<template>
<div class="top-page-container w100p font-axiroma-regular mh2" v-if="!loading && loader">
  <!-- =============== Device Management ================ ---->
  <div class="text-left font-bold f2 pb1">Device Management</div>
  <v-card>
    <v-card-title class="f1-7 pv0-3 pb2">
        <span class="device_length pt2"> List Of Deployed Devices ({{deployedDeviceList.length}}) </span>
      <v-spacer></v-spacer>
      <v-text-field
        class="f1-7"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="table"
      item-key="deviceId"
      single-select
      :headers="headers"
      :items="deployedDeviceList"
      :search="search"
      :items-per-page="5"
    >
    <template v-slot:[`item.aiModels`]="{ item }">
      <span class="underline text-violet f1-2 mr5 pointer" @click="openModal(item.aiModels)"> {{ item.aiModels[0].name }} </span>
      <!-- <div class="underline text-violet f1-2 mr5" @click="openModal(item.aiModels)">Show more</div> -->
    </template>
    <template v-slot:[`item.primaryMechanism`]="{ item }">
      <span @click="navigate(item.primaryMechanism, item)"> {{ item.primaryMechanism }} </span>
    </template>
    <template v-slot:[`item.configRules`]="{ item }">
      <span class="underline text-violet f1-2 mr5 pointer"  @click="navigate('Algo', item)"> {{ item.configRules }} </span>
    </template>
    </v-data-table>
  </v-card>
  <div class="model-overlay">
    <bh-modal-overlay :showModal="showModal" @modal-close="openModal">
      <div class="model-container f1-5 h35 w30 br1-4" :data="modelsData">
        <div class="models-header display-flex flex-row justify-center align-center h5 br-top1-2">
          Models List
        </div>
        <v-list rounded>
      <v-list-item-group color="primary" class="model-group-list maxh22 overflow-auto">
        <v-list-item
          v-for="data in modelsData" :key="data.name">
          <v-list-item-content>
            {{data.name}}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
      <bh-button
        btnClass="bg-violet text-white border-none br0-5 f1-5 pa1 w30p ma0"
        @output.self="openModal"
      >
        close
      </bh-button>
      </div>
    </bh-modal-overlay>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { updateValue } from '@/utils'
import { getActiveEngines } from '@/services/performance'
import { getAllRuleSets } from '@/services/configurations'
export default {
  name: 'bh-deployed-device-history',
  data() {
    return {
      search: '',
      primaryMechanism: '',
      activatedModel: '',
      activatedRule: '',
      headers: [
        {
          text: 'Device Id',
          align: 'start',
          value: 'deviceId'
        },
        { text: 'Device Name', value: 'deviceName' },
        { text: 'Deployed By', value: 'deployedBy' },
        { text: 'Deployed Status', value: 'deployedStatus' },
        { text: 'AI Models', value: 'aiModels' },
        { text: 'Configuration Rules', value: 'configRules' }
      ],
      deployedDeviceList: [
        {
          deviceId: 'b2fa9cbc-410f-4892-987b-bf5eaaf6991f',
          deviceName: 'Device 1',
          deployedBy: 'Jason Troxel',
          deployedStatus: 'Success',
          configRules: 'test1'
        },
        {
          deviceId: 'a2fa9cbc-410f-4892-987b-bf5eaaf6991f',
          deviceName: 'Device 2',
          deployedBy: 'Jason William',
          deployedStatus: 'Success',
          configRules: 'test2'
        },
        {
          deviceId: 'hu2a9cbc-410f-4892-987b-bf5eaaf6991f',
          deviceName: 'Device 3',
          deployedBy: 'Ammit G',
          deployedStatus: 'Success',
          configRules: 'Default Settings'
        },
        {
          deviceId: 'f2aa9cbc-410f-4892-987b-bf5eaaf6991f',
          deviceName: 'Device 4',
          deployedBy: 'Marrie',
          deployedStatus: 'Success',
          configRules: 'test1'
        },
        {
          deviceId: 'k02a9cbc-410f-4892-987b-bf5eaaf6991f',
          deviceName: 'Device 5',
          deployedBy: 'Prahlad Menon',
          deployedStatus: 'Success',
          configRules: 'test4'
        },
        {
          deviceId: 'f45a9cbc-410f-4892-987b-bf5eaaf6991f',
          deviceName: 'Device 6',
          deployedBy: 'Vikas Rana',
          deployedStatus: 'Success',
          configRules: 'test6'
        },
        {
          deviceId: 'a92a9cbc-410f-4892-987b-bf5eaaf6991f',
          deviceName: 'Device 7',
          deployedBy: 'Sam Amirfar',
          deployedStatus: 'Success',
          configRules: 'test7'
        }
      ],
      showModal: false,
      modelsData: [],
      loader: false
    }
  },
  computed: {
    ...mapGetters(['loading'])
  },
  async mounted() {
    const engineData = await getActiveEngines({ where: { } })
    const rulesets = await getAllRuleSets()
    engineData.filter(data => {
      if (data.engineName === 'RE') {
        this.primaryMechanism = data.isPrimary ? 'Algo' : 'AI'
      }
      if (data.isActive) {
        this.activatedModel = `${data.modelName}`
      }
    })
    rulesets.filter(data => {
      if (data.state === 'active') {
        this.activatedRule = `${data.name}`
      }
    })
    const obj = [
      {
        text: 'Primary Mechanism',
        value: 'primaryMechanism'
      },
      {
        text: 'Activated Model',
        value: 'activatedModel'
      },
      {
        text: 'Activated Rule',
        value: 'activatedRule'
      }
    ]
    this.headers.push(...obj)
    this.updateTableData(this.deployedDeviceList)
    this.loader = true
  },
  methods: {
    updateValue,
    openModal(data) {
      this.modelsData = data
      this.showModal = !this.showModal
    },
    navigate(selected, row) {
      if (selected === 'AI') {
        this.$router.push({
          name: 'Performance'
        })
      } else if (selected === 'Algo') {
        this.$router.push({
          name: 'Configurations',
          params: { selectedRule: row.configRules }
        })
      }
    },
    updateTableData(data) {
      data.forEach(element => {
        element.primaryMechanism = this.primaryMechanism
        element.activatedModel = this.activatedModel
        element.activatedRule = this.activatedRule
        element.aiModels = [{
          name: 'Model1'
        },
        {
          name: 'Model2'
        },
        {
          name: 'Model3'
        }]
      })
    }
  }
}
</script>
<style scoped>

.spacer {
  flex-grow: 10!important;
}

table {
  border-collapse: collapse;
}
tbody tr:hover {
  background-color: var(--gainsboro-white);
  cursor: pointer;
}
th {
  color: var(--pulse-color-dark);
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
}
::v-deep .v-data-table-header>tr>th:nth-child(1)  {
 padding-bottom: 20px;
}
td {
  padding: 1.5rem;
}
::v-deep .v-data-table-header>tr>th>span {
    font-size: 1.6rem;
    color: var(--pulse-color-dark);
}
::v-deep .v-data-table__wrapper>table>tbody>tr>td {
 font-size: 1.3rem;
}
::v-deep .v-label {
  font-size: 12px;
}
::v-deep .v-icon {
  font-size: 18px;
}
::v-deep .v-data-footer {
  font-size: 12px;
}
::v-deep .v-data-footer__select{
    display: none;
}
.model-container {
  background-color: var(--gainsboro-white);
}
.models-header {
  background: var(--pulse-color-dark);
  color: var(--gainsboro-white);
}

</style>
